import React from "react";

import { getGlobalMap } from "./mapInstance";

import { LatLng, MapMarker } from "../../../utils/models";
import { YELLOW_MAP_INITIAL_ZOOM } from "../../../constants/configs";

/**
 * Displays a custom map marker and icon at the user's current location.
 *
 * @param options
 */
export function useHomeMarker(options: {
  ymReady: boolean;
  userLatLng?: LatLng;
}) {
  const { ymReady, userLatLng } = options;
  React.useEffect(() => {
    if (!ymReady) return;
    let userLocationMarker: MapMarker | null = null;
    window.ym.ready(function (modules: any) {
      if (userLatLng) {
        const locationIcon = window.ym.divIcon({
          className: "userLocation",
          iconSize: window.ym.point(15, 15),
        });
        userLocationMarker = window.ym.latLng(userLatLng.lat, userLatLng.lng);
        const marker = (window.ym.marker = new modules.provider.Marker(
          userLocationMarker
        ));
        const map = getGlobalMap();

        marker.setIcon(locationIcon);
        marker.addTo(map);

        map?.setZoom(YELLOW_MAP_INITIAL_ZOOM);
      }
    });
  }, [ymReady, userLatLng]);
}
