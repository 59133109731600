import React from "react";

import { setGlobalMap } from "./mapInstance";

import {
  ING_FRANKFURT_LATITUDE,
  ING_FRANKFURT_LONGITUDE,
} from "../../../constants/default";
import {
  YELLOW_MAP_INITIAL_ZOOM,
  YELLOW_MAP_MIN_ZOOM,
} from "../../../constants/configs";

import { CONFIG } from "../../../../config/viteConfig";

function loadYellowmap() {
  const apiKey = CONFIG.apiKey;
  const securityId = CONFIG.securityId;
  const partnerId = CONFIG.partnerId;

  const ymScript = document.createElement("script");
  const ymUrlParams = [
    "libraries=enterprise",
    `apiKey=${apiKey}`,
    `securityID=${encodeURIComponent(securityId)}`,
    `Partner=${partnerId}`,
  ].join("&");

  const baseUrl = CONFIG.yellowMapApiRoot;

  const ymUrl = `${baseUrl}/api/loader?${ymUrlParams}`;

  ymScript.setAttribute("src", ymUrl);
  document.body.appendChild(ymScript);
  return ymScript;
}

export function useYellowMap(element: HTMLDivElement | null) {
  const [ymReady, setYmReady] = React.useState(false);

  const isMounted = React.useRef<boolean>(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (!element) {
      return;
    }

    function createMap() {
      if (!isMounted.current) {
        return;
      }

      const map = window.ym.map(element, {
        center: window.ym.latLng(
          ING_FRANKFURT_LATITUDE,
          ING_FRANKFURT_LONGITUDE
        ),
        zoom: YELLOW_MAP_INITIAL_ZOOM,
        minZoom: YELLOW_MAP_MIN_ZOOM,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        gestureHandling:
          process.env.NODE_ENV === "production" ? "cooperative" : undefined,
      });
      Object.assign(window, { map });

      setGlobalMap(map);

      //   const markers = window.ym.markerClusterGroup({
      //     showCoverageOnHover: false,
      //     maxClusterRadius: 50,
      //     iconCreateFunction: function (cluster: MarkerClusterGroup) {
      //       const children = cluster.getAllChildMarkers();

      //       const numResults = Intl.NumberFormat("de-DE", {
      //         maximumSignificantDigits: 3,
      //       }).format(children.length);

      //       return window.ym.divIcon({
      //         className: "ing-cluster",
      //         html: `<b>${numResults}</b>`,
      //         iconSize: window.ym.point(50, 50),
      //       });
      //     },
      //   });

      //   map.addLayer(markers);
      // setGlobalMarkers(markers);

      setYmReady(true);
    }

    if (ymReady) {
      return;
    }

    const ymScript = loadYellowmap();
    ymScript.onload = () => window.ym.ready(createMap);
    return () => {
      Object.assign(window, { map: null });
      Object.assign(window, { markers: null });
    };
  }, [element]);

  return {
    ymReady,
  };
}
