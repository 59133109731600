import { atom, useAtom } from "jotai";

export interface GeolocationStatus {
  isEnabled: boolean;
  hasError: boolean;
  errorMessage?: string;
  useApplicationWithGeolocation: boolean;
}

const geolocationStatusAtom = atom<GeolocationStatus | undefined>(undefined);

export const useGeolocationStatus = () => {
  const [geolocationStatus, setGeolocationStatus] = useAtom(
    geolocationStatusAtom
  );
  return { geolocationStatus, setGeolocationStatus };
};
