import React from "react";

import { getGlobalMap, getGlobalMarkers, setGlobalMarkers } from "./mapInstance";

import { createMarker } from "./MapFunctions";
import { getPartnerIdentifier } from "../getPartnerIdentifier";
import { PartnerHashMap } from "../../../hooks/usePartners/usePartners";
import { MarkerClusterGroup, MarkerRefs } from "../../../utils/models";
import { Partner } from "../../../hooks/usePartners/interfaces/Partner";
import { useMarkerClick } from "./useMarkerClick";

/**
 * Displays map markers with proper icons for all given search result ATMs
 *
 * @param options
 */
export function useResultMarkers(options: {
    ymReady: boolean;
    // onMarkerClick: (addressItemId: string) => void;
    partners: PartnerHashMap;
    markersById: MarkerRefs;
}) {
    const { ymReady, partners, markersById } = options;

    const handleMarkerClick = (partnerId: string) => {
        onMarkerClick(partnerId);
    };

    const { onMarkerClick } = useMarkerClick({ partners });

    React.useEffect(
        function drawResultMarkers() {
            if (!ymReady) {
                return;
            }

            window.ym.ready(function () {
                const map = getGlobalMap();
                const markers = window.ym.markerClusterGroup({
                    showCoverageOnHover: false,
                    maxClusterRadius: 50,
                    iconCreateFunction: function (cluster: MarkerClusterGroup) {
                        const children = cluster.getAllChildMarkers();

                        const numResults = Intl.NumberFormat("de-DE", {
                            maximumSignificantDigits: 3,
                        }).format(children.length);

                        return window.ym.divIcon({
                            className: "ing-cluster",
                            html: `<b>${numResults}</b>`,
                            iconSize: window.ym.point(50, 50),
                        });
                    },
                });

                if (!map) {
                    console.log("No map found", map);
                    return;
                }

                map.addLayer(markers);
                setGlobalMarkers(markers);
                if (partners) {
                    const partnerArray = Object.values(partners);

                    partnerArray.forEach((elem: Partner) => {
                        const id = getPartnerIdentifier(elem);

                        const currentMarker = markersById.current![id];

                        if (!currentMarker) {
                            const marker = createMarker({
                                partner: elem,
                                onClick: handleMarkerClick,
                                //target: getGlobalMarkers(),
                                target: markers,
                            });
                            if (marker) {
                                markersById.current[id] = marker;
                            }
                        }
                    });

                    const nextKeys = Object.keys(partners);
                    const prevKeys = Object.keys(markersById.current);

                    const obsoleteKeys = prevKeys.filter((key) => !nextKeys.includes(key));
                    obsoleteKeys.forEach((id) => {
                        const marker = markersById.current[id];
                        const layer = getGlobalMarkers();
                        if (marker && layer) {
                            marker.removeFrom(layer);
                        }
                        delete markersById.current[id];
                    });
                }
            });
        },
        [partners, ymReady, markersById],
    );
}
