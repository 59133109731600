import { AppShell, Container } from "@mantine/core";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";

import { PageEndBar } from "./PageEndBar/PageEndBar";

import { YellowMapsContainer } from "./YellowMaps/YellowMapsContainer";
import { CookieBanner } from "./CookieBanner/CookieBanner";

import { Route, Routes } from "react-router-dom";

import { DetailPartnerPage } from "./DetailPartnerPage/DetailPartnerPage";
import { IngContent } from "./IngContent";

export const HOME_ROUTE = "/";
export const PARTNER_PATH = (partnerId: string) => `berater/${partnerId}`;

export const Home = () => {
    return (
        <AppShell
            bg="#f7f7f7"
            padding="md"
            m="0 auto"
            styles={() => ({
                root: {
                    display: "flex",
                    gap: "30px",
                    flexDirection: "column",
                },

                body: {
                    flex: 1,
                },

                main: {
                    flex: 1,
                    height: 100,
                },
            })}
        >
            <Header />
            <YellowMapsContainer />
            <Container size="lg">
                <Routes>
                    <Route path={HOME_ROUTE} element={<IngContent />} />
                    <Route path={PARTNER_PATH(":partnerId")} element={<DetailPartnerPage />} />
                </Routes>
                <PageEndBar />
            </Container>
            <CookieBanner />
            <Footer showCookieInfos={true} />
        </AppShell>
    );
};
