import { Box, List, Anchor } from "@mantine/core";
import styles from "./Footer.module.css";
import { CookieModal } from "../CookieModal/CookieModal";
import { useState } from "react";

interface FooterProps {
    showCookieInfos: boolean;
}

export const Footer: React.FC<FooterProps> = (props: FooterProps) => {
    const { showCookieInfos } = props;
    const [opened, setOpened] = useState<boolean>(false);
    return (
        <Box>
            <List
                display="flex"
                listStyleType="none"
                style={{ listStyle: "none", gap: "30px", justifyContent: "center" }}
                bg="white"
                p="20px"
                styles={{
                    item: {
                        fontSize: "16px",
                        color: "#767676",
                        lineHeight: "24px",
                        textDecoration: "none",
                    },
                }}
            >
                <List.Item>
                    <Anchor
                        className={styles.footerLink}
                        href={"https://www.ing.de/ueber-uns/unternehmen/impressum/"}
                        target="_blank"
                    >
                        Impressum
                    </Anchor>
                </List.Item>
                <List.Item>
                    <Anchor className={styles.footerLink} href={"https://www.ing.de/datenschutz/"} target="_blank">
                        Datenschutz
                    </Anchor>
                </List.Item>
                <List.Item>
                    <Anchor
                        className={styles.footerLink}
                        href="/site/assets/files/assets/faqs-partner-empfehlungswebsite.pdf"
                        target="_blank"
                    >
                        FAQ
                    </Anchor>
                </List.Item>
                {showCookieInfos && (
                    <List.Item>
                        <Anchor onClick={() => setOpened(true)} className={styles.footerLink} target="_blank">
                            Cookie-Information
                        </Anchor>
                    </List.Item>
                )}
            </List>
            <CookieModal isOpen={opened} onClose={() => setOpened(false)} />
        </Box>
    );
};
