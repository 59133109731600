import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import { HOME_ROUTE } from "../Home";
import { useSelectedPartner } from "../../hooks/useSelectPartner";

export const Header = () => {
    const { setSelectedPartner } = useSelectedPartner();
    const handleLogoClick = () => {
        setSelectedPartner(undefined);
    };
    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerWrapper}>
                <Link to={HOME_ROUTE} onClick={handleLogoClick}>
                    <img src="/site/assets/files/assets/primary-logo.jpg" className={styles.ingLogo} alt="ING Logo" />
                </Link>
                <Link to={HOME_ROUTE} onClick={handleLogoClick}>
                    <img
                        src="/site/assets/files/assets/mein-baufinanzierungsberater-logo.svg"
                        className={styles.baufiLogo}
                        alt="baufinanzierungsberater logo"
                    />
                </Link>
            </div>
        </div>
    );
};
