import { Anchor, Grid, Group, Image, List, Paper, Stack, Text, Title, Tooltip } from "@mantine/core";

import styles from "./DetailPartnerPage.module.css";
import { usePartners } from "../../hooks/usePartners/usePartners";
import { useNavigate, useParams } from "react-router-dom";
import { HOME_ROUTE } from "../Home";

import { ContactForm } from "./ContactForm/ContactForm";
import { useSelectedPartner } from "../../hooks/useSelectPartner";

export const DetailPartnerPage = () => {
    const partners = usePartners();
    const params = useParams();
    const navigate = useNavigate();
    const { setSelectedPartner } = useSelectedPartner();

    const partnerId = params.partnerId;

    if (!partnerId) return null;

    const selectedPartner = partners[partnerId];

    const imageUrl = selectedPartner?.portrait
        ? selectedPartner.portrait.large
        : "/site/assets/files/assets/profil-dummy.png";
    const handleLinkClick = () => {
        navigate(HOME_ROUTE);
        setSelectedPartner(undefined);
    };

    return (
        <Stack>
            <Anchor underline="never" onClick={handleLinkClick} className={styles.detailPageBackButton}>
                Zurück
            </Anchor>
            <Paper p="xl" radius="12px">
                <Grid>
                    <Grid.Col span={3} pl={"20px"} pr={"25px"}>
                        <Stack>
                            <Image src={imageUrl} className={styles.partnerPicture} />
                            <Stack gap="0">
                                <Text className={styles.partnerLeftColumnText}>{selectedPartner.street}</Text>
                                <Text
                                    className={styles.partnerLeftColumnText}
                                >{`${selectedPartner.zip} ${selectedPartner.city}`}</Text>
                            </Stack>
                            <Stack gap="0">
                                <Text className={styles.partnerLeftColumnText}>T: {selectedPartner.landline}</Text>
                                {selectedPartner.mobile && (
                                    <Text className={styles.partnerLeftColumnText}>M: {selectedPartner.mobile}</Text>
                                )}
                            </Stack>
                            {selectedPartner.e_mail ? (
                                <Anchor
                                    underline="never"
                                    href={`mailto:${selectedPartner.e_mail}`}
                                    target="_blank"
                                    className={styles.partnerLeftColumnLink}
                                    w="xs"
                                >
                                    <Text style={{ textDecoration: "underline" }}>{selectedPartner.e_mail}</Text>
                                </Anchor>
                            ) : undefined}

                            {selectedPartner.website ? (
                                <Anchor
                                    underline="never"
                                    href={selectedPartner.website}
                                    className={styles.partnerLeftColumnLink}
                                    w="xs"
                                >
                                    <Text style={{ textDecoration: "underline" }}>Website</Text>
                                </Anchor>
                            ) : undefined}

                            <Group>
                                {selectedPartner.facebook && (
                                    <Anchor href={selectedPartner.facebook}>
                                        <Image src="/site/assets/files/assets/Facebook.svg" />
                                    </Anchor>
                                )}
                                {selectedPartner.xing && (
                                    <Anchor href={selectedPartner.xing}>
                                        <Image src="/site/assets/files/assets/Xing.svg" />
                                    </Anchor>
                                )}
                                {selectedPartner.twitter && (
                                    <Anchor href={selectedPartner.twitter}>
                                        <Image src="/site/assets/files/assets/Twitter.svg" />
                                    </Anchor>
                                )}
                                {selectedPartner.linkedin && (
                                    <Anchor href={selectedPartner.linkedin}>
                                        <Image src="/site/assets/files/assets/LinkedIn.svg" />
                                    </Anchor>
                                )}
                            </Group>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={9} pl={"20px"} pr={"20px"}>
                        <Stack gap="xl">
                            <Stack gap="0">
                                <Title order={1} className={styles.partnerHeadline}>
                                    {`${selectedPartner.firstname} ${selectedPartner.lastname}`.replace("&amp;", "&")}
                                </Title>
                                <Title order={5} className={styles.partnerSubheadline}>
                                    {selectedPartner.company.replace("&amp;", "&")}
                                </Title>
                            </Stack>

                            <Group align="flex-start" wrap="nowrap">
                                <Stack className={styles.factsList}>
                                    <Text className={styles.partnerCategorieHeadline}>Fakten</Text>
                                    <List ta="left">
                                        {selectedPartner.facts.map((fact, index) => {
                                            return (
                                                <List.Item key={index} className={styles.partnerTeaserList}>
                                                    {fact.replace("&amp;", "&")}
                                                </List.Item>
                                            );
                                        })}
                                    </List>
                                </Stack>
                                <Stack className={styles.badgesGroup}>
                                    {selectedPartner.badges.ausgezeichnete_kompetenz ||
                                    selectedPartner.badges.hohe_kundenorientierung ||
                                    selectedPartner.badges.starke_leistung ||
                                    selectedPartner.badges.top_qualitaet ||
                                    selectedPartner.badges.breites_ing_portfolio ||
                                    selectedPartner.badges.spezial_wissen_modernisierung ? (
                                        <Text className={styles.partnerCategorieHeadline}>Qualitätszeichen</Text>
                                    ) : undefined}

                                    <Group align="flex-start" h="70px" wrap="nowrap">
                                        {selectedPartner.badges.starke_leistung && (
                                            <Tooltip label="Starke Leistung" withArrow>
                                                <Image
                                                    src="/site/assets/files/assets/badge-leistung.svg"
                                                    className={styles.partnerTeaserIcon}
                                                />
                                            </Tooltip>
                                        )}

                                        {selectedPartner.badges.top_qualitaet && (
                                            <Tooltip label="Top Qualität" withArrow>
                                                <Image
                                                    src="/site/assets/files/assets/badge-quality.svg"
                                                    className={styles.partnerTeaserIcon}
                                                />
                                            </Tooltip>
                                        )}
                                        {selectedPartner.badges.hohe_kundenorientierung && (
                                            <Tooltip label="Hohe Kundenorientierung" withArrow>
                                                <Image
                                                    src="/site/assets/files/assets/badge-kundenorientierung.svg"
                                                    className={styles.partnerTeaserIcon}
                                                />
                                            </Tooltip>
                                        )}
                                        {selectedPartner.badges.breites_ing_portfolio && (
                                            <Tooltip label="Breites ING-Portfolio" withArrow>
                                                <Image
                                                    src="/site/assets/files/assets/badge-portfolio.svg"
                                                    className={styles.partnerTeaserIcon}
                                                />
                                            </Tooltip>
                                        )}
                                        {selectedPartner.badges.ausgezeichnete_kompetenz && (
                                            <Tooltip label="Ausgezeichnete Kompetenz" withArrow>
                                                <Image
                                                    src="/site/assets/files/assets/badge-special-know-how.svg"
                                                    className={styles.partnerTeaserIcon}
                                                />
                                            </Tooltip>
                                        )}
                                        {selectedPartner.badges.spezial_wissen_modernisierung && (
                                            <Tooltip label="Spezial-Wissen Modernisierung" withArrow>
                                                <Image
                                                    src="/site/assets/files/assets/badge-green-know-how.svg"
                                                    className={styles.partnerTeaserIcon}
                                                />
                                            </Tooltip>
                                        )}
                                    </Group>
                                </Stack>
                            </Group>
                            <Text className={styles.partnerCategorieHeadline}>Über mich</Text>
                            <Text className={styles.partnerParagraph}>{selectedPartner.about}</Text>

                            <Text className={styles.partnerCategorieHeadline}>Schreiben Sie mir</Text>
                            <ContactForm partnerId={selectedPartner.id} />
                            {selectedPartner.legalcopy && (
                                <Stack>
                                    <Text fw={"bold"} className={styles.partnerLegalcopyHeadline}>
                                        Angaben nach Telemediengesetz
                                    </Text>
                                    <Text className={styles.partnerLegalcopyText}>{selectedPartner.legalcopy}</Text>
                                </Stack>
                            )}
                        </Stack>
                    </Grid.Col>
                </Grid>
            </Paper>
        </Stack>
    );
};
