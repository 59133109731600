import { atom, useAtom } from "jotai";
import { Partner } from "./interfaces/Partner";
export type PartnerHashMap = Record<string, Partner>;

const partnersAtom = atom(async (_, { signal }) => {
  try {
    const configPath = "/partners_json";

    const response = await fetch(configPath, { signal });

    const partners: Partner[] = await response.json();

    const partnerHashMap: PartnerHashMap = {};

    partners.forEach((item) => (partnerHashMap[item.id] = item));

    return partnerHashMap;
  } catch (error) {
    let errorMessage = "Could not load Partners from ./partners_json";

    if (error instanceof Error) {
      errorMessage += `\n${error.message}`;
    }

    throw Error(errorMessage);
  }
});

export const usePartners = () => {
  const [partners] = useAtom(partnersAtom);
  return partners;
};
