import { atom, useAtom } from "jotai";
import { GeoEntity } from "../api/interfaces/GeoEntity";

const selectedCityAtom = atom<GeoEntity | undefined>(undefined);

//  Ausgewähltes Suchergebnis als komplette Geo-Entity nach Klick auf einen ListenEintrag
export const useSelectedCity = () => {
  const [selectedCity, setSelectedCity] = useAtom(selectedCityAtom);
  return { selectedCity, setSelectedCity };
};
