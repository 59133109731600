export const MARKER_ZINDEX_DEFAULT = 10000;
export const MARKER_ZINDEX_ING = 10001;
export const MARKER_ZINDEX_SELECTED = 10002;
export const MARKER_ZINDEX_USER = 10003;

export const icon = {
    iconUrl: "/site/assets/files/assets/pin.svg",
    iconRetinaUrl: "/site/assets/files/assets/badge-portfolio.svg",
    iconSize: [40, 49],
    iconAnchor: [15, 49],
    popupAnchor: [0, -48],
};
