import { Paper, Stack, Text, Grid } from "@mantine/core";
import styles from "./MainPage.module.css";

export const MainPage = () => {
    return (
        <Paper radius="md" p="30px 45px">
            <Stack gap="xl">
                <Grid justify="space-around" gutter="xl">
                    <Grid.Col span={4} ta="left">
                        <Text className={styles.leftColumnHeadline}>Baufinanzierungs-Beratung mit Qualität</Text>
                    </Grid.Col>
                    <Grid.Col span={8} ta="left">
                        <Stack>
                            <Text className={styles.rightColumnText}>
                                Geben Sie Ihre Baufinanzierung in gute Hände und lassen Sie sich von ausgewählten
                                Baufinanzierungsvermittlerinnen und -vermittlern beraten.
                            </Text>
                            <Text className={styles.rightColumnText}>
                                Unsere Empfehlung: Setzen Sie auf die Besten der Besten – wir haben für Sie aus unseren
                                über 9.000 Vermittlerinnen und Vermittlern die Personen/Unternehmen ausgewählt, die sich
                                durch besondere Kriterien ausgezeichnet haben.
                            </Text>
                            <Text className={styles.rightColumnText}>
                                Wir wollen Ihnen mehr bieten als allein gute Konditionen, nämlich eine qualifizierte
                                Beratung vor Ort.
                            </Text>
                        </Stack>
                    </Grid.Col>
                </Grid>
                <Grid justify="space-around" gutter="xl">
                    <Grid.Col span={4} ta="left">
                        <Text className={styles.leftColumnHeadline}>Nur die Besten</Text>
                    </Grid.Col>
                    <Grid.Col span={8} ta="left">
                        <Stack gap="xl">
                            <Text className={styles.rightColumnText}>
                                Damit Sie sich auf Ihre Beratung vor Ort verlassen können, präsentieren wir Ihnen hier
                                nur Partnerinnen und Partner, die sich bei uns bewährt haben. Sie haben uns mit ihrer
                                Leistung überzeugt. Ob die Qualität der eingereichten Anträge, das Engagement bei der
                                Weiterbildung oder der Einsatz im Finanzierungsgespräch vor Ort – diese Beraterinnen und
                                Berater können wir Ihnen empfehlen.
                            </Text>
                            <Text className={styles.rightColumnText}>
                                Weil unterschiedliche Baufinanzierungsberaterinnen und -berater auch unterschiedliche
                                Stärken besitzen, haben wir die Besten der Besten mit einem oder mehreren
                                Qualitätszeichen markiert. Was zeichnet jeden Einzelnen besonders aus?
                            </Text>
                            <Stack gap="xl">
                                <Grid>
                                    <Grid.Col span={1}>
                                        <img
                                            src="/site/assets/files/assets/badge-leistung.svg"
                                            className={styles.partnerIcon}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={11}>
                                        <Stack>
                                            <Text className={styles.rightColumnSubHeadline}>Starke Leistung</Text>
                                            <Text className={styles.rightColumnText}>
                                                Beraterinnen und Berater mit diesem Zeichen haben im letzten Jahr
                                                regelmäßig und in der Regel mit sehr viel Fachwissen bei uns finanziert.
                                                Das zeigt: Diese Personen setzen sich erfolgreich für Sie ein, so dass
                                                Sie mit einer schnellen Entscheidung rechnen können.
                                            </Text>
                                        </Stack>
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={1}>
                                        <img
                                            src="/site/assets/files/assets/badge-quality.svg"
                                            className={styles.partnerIcon}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={11}>
                                        <Stack>
                                            <Text className={styles.rightColumnSubHeadline}>Top Qualität</Text>
                                            <Text className={styles.rightColumnText}>
                                                Dieses Zeichen steht für Beraterinnen und Berater, die verstanden haben,
                                                welche Unterlagen wir zur Beratung brauchen. Mit vollständigen Anfragen
                                                sorgen sie dafür, dass Ihr Antrag in der Regel ganz schnell, innerhalb
                                                von 3 Tagen, von uns bearbeitet wird.
                                            </Text>
                                        </Stack>
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={1}>
                                        <img
                                            src="/site/assets/files/assets/badge-kundenorientierung.svg"
                                            className={styles.partnerIcon}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={11}>
                                        <Stack>
                                            <Text className={styles.rightColumnSubHeadline}>
                                                Hohe Kundenorientierung
                                            </Text>
                                            <Text className={styles.rightColumnText}>
                                                An diesem Zeichen erkennen Sie Baufinanzierungsberaterinnen und
                                                -berater, die auch bei 400 weiteren Banken nach einem optimalen Angebot
                                                für Sie suchen. Ihr Vorteil: Sie bekommen eine produkt- und
                                                provisionsunabhängige Baufinanzierungslösung, die zu Ihnen passt.
                                            </Text>
                                        </Stack>
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={1}>
                                        <img
                                            src="/site/assets/files/assets/badge-special-know-how.svg"
                                            className={styles.partnerIcon}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={11}>
                                        <Stack>
                                            <Text className={styles.rightColumnSubHeadline}>
                                                Ausgezeichnete Kompetenz
                                            </Text>
                                            <Text className={styles.rightColumnText}>
                                                Dieses Zeichen steht für persönliche Beraterinnen und Berater, die
                                                zusätzlich zu den gesetzlichen Erfordernissen das
                                                Qualifying-Weiterbildungsprogramm der Frankfurt School of Finance &
                                                Management erfolgreich durchlaufen haben. So können Sie sicher sein,
                                                dass Sie mit Kompetenz und aktuellem Fachwissen beraten werden.
                                            </Text>
                                        </Stack>
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={1}>
                                        <img
                                            src="/site/assets/files/assets/badge-portfolio.svg"
                                            className={styles.partnerIcon}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={11}>
                                        <Stack>
                                            <Text className={styles.rightColumnSubHeadline}>Breites ING-Portfolio</Text>
                                            <Text className={styles.rightColumnText}>
                                                Sie erwarten im Baufinanzierungsgespräch mehr als die Finanzierung Ihrer
                                                Immobilie? Dann orientieren Sie sich an Beraterinnen und Beratern, die
                                                auch einen ING-Ratenkredit und einen ING-Firmenkredit im Portfolio
                                                haben.
                                            </Text>
                                        </Stack>
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={1}>
                                        <img
                                            src="/site/assets/files/assets/badge-green-know-how.svg"
                                            className={styles.partnerIcon}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={11}>
                                        <Stack>
                                            <Text className={styles.rightColumnSubHeadline}>
                                                Spezial-Wissen Modernisierung
                                            </Text>
                                            <Text className={styles.rightColumnText}>
                                                Modernisierung und Sanierung sind das Thema der Zukunft. Auch bei der
                                                Baufinanzierung. Beraterinnen und Berater, die in punkto
                                                &quot;Energieeffizienz&quot; gut geschult sind, erkennen Sie an diesem
                                                Zeichen.
                                            </Text>
                                        </Stack>
                                    </Grid.Col>
                                </Grid>
                            </Stack>
                        </Stack>
                    </Grid.Col>
                </Grid>
                <Grid justify="space-around" gutter="xl">
                    <Grid.Col span={4} ta="left">
                        <Text className={styles.leftColumnHeadline}>Wichtige Info für Sie</Text>
                    </Grid.Col>
                    <Grid.Col span={8} ta="left">
                        <Stack>
                            <Text className={styles.rightColumnSubHeadline}>Disclaimer / Haftungsausschluss</Text>
                            <Text className={styles.rightColumnText}>
                                Die auf dieser Seite vorgestellten Baufinanzierungsvermittler wurden auf Grund von
                                bestimmten Kriterien nach bestem Wissen ausgewählt. Die Kriterien werden einmal jährlich
                                auf Grundlage der Daten des Vorjahres ermittelt und gelten jeweils für ein Jahr.
                            </Text>
                            <Text className={styles.rightColumnText}>
                                Die Vorschläge stellen lediglich unverbindliche Empfehlungen der ING-DiBa AG und keine
                                Beratung dar. Auch die Qualitätszeichen werden ausschließlich von der ING vergeben. Die
                                ING übernimmt keine Haftung für die Qualität der Vermittlungs- oder Beratungsleistung
                                des jeweiligen Baufinanzierungsvermittlers und für etwaige Schäden, die auf Grund einer
                                fehlerhalten oder falschen Vermittlungs- oder Beratungsleistung des jeweiligen
                                Baufinanzierungsvermittlers entstehen.
                            </Text>
                            <Text className={styles.rightColumnText}>
                                Jeder Baufinanzierungsvermittler ist für die Angaben auf der Partner-Empfehlungswebsite
                                (www.mein-baufinanzierungsberater.de) selbst verantwortlich. Die ING übernimmt keine
                                Haftung für Schäden, welche auf Grund von falschen Angaben des jeweiligen
                                Baufinanzierungsvermittlers entstehen.
                            </Text>
                            <Text className={styles.rightColumnText}>
                                Der Ausschluss gilt nicht für Schäden aus der Verletzung des Lebens, des Körpers oder
                                der Gesundheit, die auf einer fahrlässigen Pflichtverletzung der ING-DiBa AG oder einer
                                vorsätzlichen oder fahrlässigen Pflichtverletzung eines gesetzlichen Vertreters oder
                                Erfüllungsgehilfen der ING-DiBa AG beruhen. Der Ausschluss gilt nicht für sonstige
                                Schäden, die auf einer grob fahrlässigen Pflichtverletzung der ING-DiBa AG oder auf
                                einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung eines gesetzlichen
                                Vertreters oder Erfüllungsgehilfen der ING-DiBa AG beruhen.
                            </Text>
                        </Stack>
                    </Grid.Col>
                </Grid>
            </Stack>
        </Paper>
    );
};
