import { Stack, Title, Grid } from "@mantine/core";
import { SmallPartnerTeaser } from "./smallPartnerTeaser/SmallPartnerTeaser";

import { useSelectedCity } from "../hooks/useSelectedCity";
import { useNearestPartners } from "../hooks/useNearestPartners/useNearestPartners";
import { Partner } from "../hooks/usePartners/interfaces/Partner";
import styles from "./IngContent.module.css";

export const MorePartners = () => {
    const { selectedCity } = useSelectedCity();

    const nearestPartners = useNearestPartners(selectedCity);

    return (
        <Stack>
            {nearestPartners.selectNearesPartners?.otherPartner && (
                <Title order={2} className={styles.morePartnerHeading}>
                    Weitere Ausgezeichnete Partner
                </Title>
            )}
            <Grid>
                {nearestPartners.selectNearesPartners?.otherPartner.map((partner: Partner, index) => (
                    <Grid.Col span={4} key={index}>
                        <SmallPartnerTeaser partner={partner} />
                    </Grid.Col>
                ))}
            </Grid>
        </Stack>
    );
};
