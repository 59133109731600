import { Modal, Stack, Text, Accordion, Group, Avatar, Anchor } from "@mantine/core";
import { Footer } from "../Footer/Footer";
import styles from "./CookieModal.module.css";

interface CookieModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const CookieModal: React.FC<CookieModalProps> = (props: CookieModalProps) => {
    const { isOpen, onClose } = props;

    const charactersList = [
        {
            id: "wires",
            image: "/site/assets/files/assets/cookie-active.svg",
            label: "wires",
            content: (
                <Stack>
                    <Text>
                        Dieses Cookie wird zur Identifizierung der Session durch das Content Management System
                        Processwire genutzt
                    </Text>
                    <Text fw="bold">Verarbeitendes Unternehmen</Text>
                    <Text>ING-DiBa AG</Text>
                    <Text fw="bold">Zweck der Daten</Text>
                    <Text>
                        Diese Liste stellt die Zwecke der Datenerhebung und -verarbeitung dar. <br />- Technisch
                        notwendiges Cookie zur Identifizierung der Session und um die technische Funktionalität der
                        Webseite sicherzustellen
                    </Text>
                    <Text fw="bold">Genutzte Technologien</Text>
                    <Text>
                        Diese Liste enthält alle Technologien, mit denen dieser Dienst Daten sammelt. Typische
                        Technologien sind Cookies und Pixel, die im Browser platziert werden. <br />- Cookies,
                        JavaScript
                    </Text>
                    <Text fw="bold">Gesammelte Daten</Text>
                    <Text>
                        Diese Liste enthält alle (persönlichen) Daten, die von oder durch die Nutzung dieses Dienstes
                        gesammelt werden. <br />- Es werden keine (persönlichen) Daten gesammelt
                    </Text>
                    <Text fw="bold">Rechtliche Grundlage</Text>
                    <Text>
                        Im Folgenden wird die erforderliche Rechtsgrundlage für die Verarbeitung von Daten genannt.
                        <br />- § 25 Abs. 2 Nr. 2 TDDDG
                    </Text>
                    <Text fw="bold">Datenempfänger</Text>
                    <Text>
                        Im Folgenden werden die Empfänger der erhobenen Daten aufgelistet. <br />- ING DiBa AG
                    </Text>
                    <Text fw="bold">Ort der Verarbeitung</Text>
                    <Text>
                        Dies ist der primäre Ort, an dem die gesammelten Daten verarbeitet werden. Sollten die Daten
                        auch in anderen Ländern verarbeitet werden, werden Sie gesondert informiert. <br />- Deutschland
                    </Text>
                    <Text fw="bold">Aufbewahrungsdauer</Text>
                    <Text>
                        Die Aufbewahrungsdauer ist die Zeitspanne, in der die gesammelten Daten für die Verarbeitung
                        gespeichert werden. Die Daten müssen gelöscht werden, sobald sie für die angegebenen
                        Verarbeitungszwecke nicht mehr benötigt werden. <br />- Die Daten werden gelöscht beim Schließen
                        des Browsers
                    </Text>
                    <Text fw="bold">Datenschutzbeauftragter des verarbeitenden Unternehmens</Text>
                    <Text>
                        Nachfolgend finden Sie die E-Mail-Adresse des Datenschutzbeauftragten des verarbeitenden
                        Unternehmens.
                        <br />
                        <Anchor href="mailto:datenschutz@ing.de">datenschutz@ing.de</Anchor>
                    </Text>

                    <Text>
                        Hinweise zu den Betroffenenrechten bzw. die Datenschutzerklärung finden Sie unter dem folgenden
                        Link:
                        <br />
                        <Anchor href="https://www.ing.de/datenschutz/"> https://www.ing.de/datenschutz/</Anchor>
                    </Text>

                    <Text>
                        Weitere Informationen zu Cookies können Sie unter folgendem Link finden:
                        <br />
                        <Anchor href="https://www.ing.de/datenschutz/cookies/" target="_blank">
                            https://www.ing.de/datenschutz/cookies/
                        </Anchor>
                    </Text>
                </Stack>
            ),
        },
    ];

    interface AccordionLabelProps {
        label: string;
        image: string;
    }

    function AccordionLabel({ label, image }: AccordionLabelProps) {
        return (
            <Group wrap="nowrap">
                <Avatar src={image} radius="xl" size="sm" />
                <div>
                    <Text>{label}</Text>
                </div>
            </Group>
        );
    }

    const items = charactersList.map((item) => (
        <Accordion.Item value={item.id} key={item.label} className={styles.cookieAccordionItemHeadline}>
            <Accordion.Control>
                <AccordionLabel {...item} />
            </Accordion.Control>
            <Accordion.Panel>{item.content}</Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <Modal zIndex={100000} onClose={onClose} opened={isOpen} withCloseButton={false} size="xl">
            <Stack>
                <Text onClick={onClose} className={styles.cookieAccordionClose}>
                    Zurück
                </Text>
                <Text className={styles.cookieModalHeadline}>Detailinformationen zu den Cookies</Text>
                <Group justify="space-between">
                    <Text className={styles.cookieModalSubheadline}>Notwendige und funktionale Cookies</Text>
                    <Group p="md">
                        <img src="/site/assets/files/assets/cookie-active.svg" className={styles.cookieAccordionIcon} />
                        <Text>aktiv</Text>
                    </Group>
                </Group>
                <Text className={styles.cookieModalText}>
                    Damit bei Ihrem Besuch unserer Website alles gelingt. Ohne einige Cookies können Sie unsere Website
                    gar nicht besuchen. Zudem können wir mit Webanalyse unsere Website für Sie verbessern und eventuelle
                    Fehler erkennen und beheben.
                </Text>
                <Text className={styles.cookieModalText}>
                    Informationen zu den Cookies entnehmen Sie bitte den Folgeseiten.
                </Text>
                <Accordion chevronPosition="right">{items}</Accordion>
                <Footer showCookieInfos={false} />
            </Stack>
        </Modal>
    );
};
