import React from "react";
import { GeoEntity } from "../../../api/interfaces/GeoEntity";
import { getGlobalMap } from "./mapInstance";
import { MapMarker } from "../../../utils/models";

export function useSearchMarker(options: { ymReady: boolean; selectedCity?: GeoEntity; isDirty: boolean }) {
    const { ymReady, selectedCity, isDirty } = options;

    React.useEffect(() => {
        if (!ymReady) return;

        let searchLocationMarker: MapMarker | null = null;

        window.ym.ready(function (modules: any) {
            const map = getGlobalMap();

            if (isDirty) {
                if (window.ym.marker.original) {
                    window.ym.marker.original.remove();
                }
            }
            if (selectedCity) {
                const searchIcon = window.ym.divIcon({
                    className: "searchLocation",
                    iconSize: window.ym.point(15, 15),
                });
                searchLocationMarker = window.ym.latLng(
                    selectedCity.geometry.coordinates[1],
                    selectedCity.geometry.coordinates[0],
                );

                const marker = (window.ym.marker = new modules.provider.Marker(searchLocationMarker));
                marker.setIcon(searchIcon);

                marker.addTo(map);
            }
        });
    }, [ymReady, selectedCity, isDirty]);
}
