import { Anchor, CloseButton, Grid, Image, Paper, Stack, Text } from "@mantine/core";

import styles from "./ProfilePreview.module.css";
import { Partner } from "../../hooks/usePartners/interfaces/Partner";

interface ProfilePreviewProps {
    selectedPartner: Partner;
    handleClose: () => void;
    onProfileClick: () => void;
}

export const ProfilePreview: React.FC<ProfilePreviewProps> = (props: ProfilePreviewProps) => {
    const { selectedPartner, handleClose, onProfileClick } = props;

    const imageUrl = selectedPartner?.portrait
        ? selectedPartner?.portrait?.thumb
        : "/site/assets/files/assets/profil-dummy.png";

    return (
        <Paper radius="12px" ml="md" className={styles.previewContainer}>
            <Grid align="center">
                <Grid.Col span={4}>
                    <Image src={imageUrl} className={styles.previewImage} />
                </Grid.Col>
                <Grid.Col span={7}>
                    <Stack>
                        <Stack gap={0}>
                            <Text
                                className={styles.previewName}
                            >{`${selectedPartner.firstname} ${selectedPartner.lastname}`}</Text>
                            <Text
                                className={styles.previewCity}
                            >{`${selectedPartner.zip} ${selectedPartner.city}`}</Text>
                        </Stack>

                        <Anchor underline="never" onClick={onProfileClick} className={styles.partnerTeaserLink}>
                            <Text>Profil ansehen</Text>
                        </Anchor>
                    </Stack>
                </Grid.Col>

                <CloseButton onClick={handleClose} className={styles.closeButton} />
            </Grid>
        </Paper>
    );
};
