import { Anchor, Grid, Title } from "@mantine/core";
import { PartnerTeaser } from "./PartnerTeaser/PartnerTeaser";
import { usePartners } from "../hooks/usePartners/usePartners";
import { useSelectedCity } from "../hooks/useSelectedCity";
import React from "react";
import { Partner } from "../hooks/usePartners/interfaces/Partner";
import { useNearestPartners } from "../hooks/useNearestPartners/useNearestPartners";
import styles from "./TopPartner.module.css";
import { Link } from "react-router-dom";

export const TopPartner = () => {
    const partners = usePartners();

    const { selectedCity, setSelectedCity } = useSelectedCity();

    const { selectNearesPartners, setNearestPartners } = useNearestPartners(selectedCity);

    const [randomPartners, setRandomPartners] = React.useState<Partner[]>([]);

    const getRandomPartners = () => {
        const partnersList = Object.values(partners);
        const randomPartnersList: Partner[] = [];
        if (partnersList.length > 0) {
            for (let i = 0; i < 3; i++) {
                const rnd = Math.floor(Math.random() * partnersList.length);
                const newRandomPartner = partnersList[rnd];
                randomPartnersList.push(newRandomPartner);
            }
        }
        setRandomPartners(randomPartnersList);
    };

    const handleBackButtonClick = () => {
        setNearestPartners(undefined);
        setSelectedCity(undefined);
    };

    React.useEffect(() => {
        getRandomPartners();
    }, []);

    const showRandomPartner = !selectNearesPartners && !selectedCity;
    const showPartnerInRange = selectNearesPartners && !selectedCity;
    const showPartnerInSearch = selectNearesPartners && selectedCity;

    return (
        <>
            {selectNearesPartners?.topPartner && (
                <Anchor
                    underline="never"
                    component={Link}
                    to={"/"}
                    onClick={handleBackButtonClick}
                    className={styles.backButton}
                >
                    Zurück
                </Anchor>
            )}
            <Title fw={100} order={1} className={styles.topPartnerHeading}>
                {showRandomPartner && "Top Partner"}

                {showPartnerInRange && "Top Partner in ihrer Umgebung"}
                {showPartnerInSearch &&
                    (selectedCity.countryLongName !== null
                        ? `Top Partner für Ihre Suche "${selectedCity?.displayValue}, ${selectedCity?.countryLongName}"`
                        : `Top Partner für Ihre Suche "${selectedCity?.displayValue}"`)}
            </Title>
            {selectNearesPartners?.topPartner ? (
                <Grid>
                    {selectNearesPartners?.topPartner.map((partner: Partner, index) => (
                        <Grid.Col span={4} key={index}>
                            <PartnerTeaser partner={partner} />
                        </Grid.Col>
                    ))}
                </Grid>
            ) : (
                <Grid>
                    {randomPartners?.map((partner: Partner, index) => (
                        <Grid.Col span={4} key={index}>
                            <PartnerTeaser partner={partner} />
                        </Grid.Col>
                    ))}
                </Grid>
            )}
        </>
    );
};
