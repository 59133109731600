import { useState } from "react";
import { ContactFormData } from "./ContactForm";

export const useSendForm = (contactFormData: ContactFormData) => {
    const [formSend, setFormSend] = useState<boolean>(false);
    const [hasFormError, setHasFormError] = useState<boolean>(false);

    const sendForm = async () => {
        setFormSend(false);
        setHasFormError(false);
        const formData = new FormData();
        Object.entries(contactFormData).forEach(([key, value]) => {
            formData.append(key, value);
        });

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set("X-Requested-With", "XMLHttpRequest");
        requestHeaders.set("Accept", "application/json; charset=utf-8");

        const res = await fetch("", {
            method: "POST",
            headers: requestHeaders,
            body: formData,
        });
        setFormSend(true);

        if (res.status !== 200) {
            setHasFormError(true);
            console.log("Error sending mail:", res);
        }
    };

    return {
        formSend,
        sendForm,
        hasFormError,
    };
};
