interface DistanceFromLatLonInKmOptions {
  partnerLat: string;
  partnerLng: string;
  currentLocationLat: number;
  currentLocationLng: number;
}

export const getDistanceFromLatLonInKm = (
  options: DistanceFromLatLonInKmOptions
) => {
  const lat = parseFloat(options.partnerLat);
  const lng = parseFloat(options.partnerLng);

  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(options.currentLocationLat - lat); // deg2rad below
  const dLon = deg2rad(options.currentLocationLng - lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat)) *
      Math.cos(deg2rad(lng)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};
