import {
    SMARTPHONE_UPPER_BOUND,
    TABLET_S_LOWER_BOUND,
    TABLET_L_LOWER_BOUND,
    TABLET_L_UPPER_BOUND,
    DESKTOP_LOWER_BOUND,
    DESKTOP_UPPER_BOUND,
} from "../constants/default";

export enum ResponsiveType {
    PHONE = "PHONE",
    TABLET_SMALL = "TABLET_SMALL",
    TABLET_LARGE = "TABLET_LARGE",
    DESKTOP_SMALL = "DESKTOP_SMALL",
    DESKTOP_LARGE = "DESKTOP_LARGE",
}

export const isPhone = () => {
    return window.innerWidth <= SMARTPHONE_UPPER_BOUND;
};
export const isTabletSmall = () => {
    return window.innerWidth >= TABLET_S_LOWER_BOUND && window.innerWidth <= TABLET_L_LOWER_BOUND;
};

export const isTabletLarge = () => {
    return window.innerWidth > TABLET_L_LOWER_BOUND && window.innerWidth <= TABLET_L_UPPER_BOUND;
};

export const isDesktopSmall = () => {
    return window.innerWidth >= DESKTOP_LOWER_BOUND && window.innerWidth <= DESKTOP_UPPER_BOUND;
};

export const isDesktopLarge = () => {
    return window.innerWidth > DESKTOP_UPPER_BOUND;
};

export const getResponsiveType = () => {
    if (isPhone()) {
        return ResponsiveType.PHONE;
    }
    if (isTabletSmall()) {
        return ResponsiveType.TABLET_SMALL;
    }
    if (isTabletLarge()) {
        return ResponsiveType.TABLET_LARGE;
    }
    if (isDesktopSmall()) {
        return ResponsiveType.DESKTOP_SMALL;
    }
    return ResponsiveType.DESKTOP_LARGE;
};
