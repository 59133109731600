import { atom, useAtom } from "jotai";
import { GeoEntity } from "../../api/interfaces/GeoEntity";
import { Partner } from "../usePartners/interfaces/Partner";
import { useEffect } from "react";
import { findNearestPartners } from "./findNearestPartners";
import { usePartners } from "../usePartners/usePartners";
import { useLocation } from "../useLocation";

export interface NearestPartners {
    topPartner: Partner[];
    otherPartner: Partner[];
}

const useNearestPartnersAtom = atom<NearestPartners | undefined>(undefined);

//  Ausgewähltes Suchergebnis als komplette Geo-Entity nach Klick auf einen ListenEintrag
export const useNearestPartners = (selectedCity: GeoEntity | undefined) => {
    const partners = usePartners();
    const { userLatLng } = useLocation();
    useEffect(() => {
        if (!selectedCity) {
            if (!userLatLng) {
                return;
            }
            const nearestPartners = findNearestPartners(partners, {
                lat: userLatLng?.lat,
                lng: userLatLng?.lng,
            });
            setNearestPartners(nearestPartners);
        } else {
            const nearestPartners = findNearestPartners(partners, {
                lat: selectedCity.geometry.coordinates[1],
                lng: selectedCity.geometry.coordinates[0],
            });
            setNearestPartners(nearestPartners);
        }
    }, [selectedCity, userLatLng]);

    const [selectNearesPartners, setNearestPartners] = useAtom(useNearestPartnersAtom);
    return { selectNearesPartners, setNearestPartners };
};
