import { useCallback, useEffect, useState } from "react";
import { geoSettings } from "../../../constants/configs";
import { useLocation } from "../../../hooks/useLocation";
import { ING_FRANKFURT_LATITUDE, ING_FRANKFURT_LONGITUDE } from "../../../constants/default";
import { useAutoMoveTo } from "./useAutoMove";
import { useGeolocationStatus } from "../../../hooks/useGeolocationStatus";

export const useGeoLocation = (options: { ymReady: boolean; enabled: boolean }) => {
    const { ymReady, enabled } = options;
    const { userLatLng, setUserLatLng } = useLocation();

    const [isLoading, setIsLoading] = useState(true);

    const { setGeolocationStatus } = useGeolocationStatus();

    const moveToUserLocation = useAutoMoveTo({
        ymReady: ymReady,
        lat: userLatLng?.lat,
        lng: userLatLng?.lng,
    });

    const moveToDefaultLocation = useAutoMoveTo({
        ymReady: ymReady,
        lat: ING_FRANKFURT_LATITUDE,
        lng: ING_FRANKFURT_LONGITUDE,
    });

    const successHandler = useCallback(
        (data: any) => {
            setUserLatLng({
                lat: data.coords.latitude,
                lng: data.coords.longitude,
            });
            setGeolocationStatus({
                isEnabled: true,
                hasError: false,
                useApplicationWithGeolocation: true,
            });
            setIsLoading(false);
            moveToUserLocation;
        },
        [setUserLatLng],
    );

    const errorHandler = useCallback(
        (error: any) => {
            let message = "";
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    message = "User denied the request for Geolocation.";
                    break;
                case error.POSITION_UNAVAILABLE:
                    message = "Location information is unavailable.";
                    break;
                case error.TIMEOUT:
                    message = "The request to get user location timed out.";
                    break;
                case error.UNKNOWN_ERROR:
                    message = "An unknown geoLocation error occurred.";
                    break;
            }

            error.userMessage = message;

            setGeolocationStatus({
                isEnabled: false,
                hasError: true,
                errorMessage: message,
                useApplicationWithGeolocation: false,
            });
            setIsLoading(false);
            moveToDefaultLocation;
        },
        [setUserLatLng],
    );

    useEffect(() => {
        if (!enabled) {
            return;
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(successHandler, errorHandler, geoSettings);
        } else {
            setGeolocationStatus({
                isEnabled: false,
                hasError: true,
                errorMessage: "Geolocation is not supported by this browser.",
                useApplicationWithGeolocation: false,
            });
            setIsLoading(false);
            console.log("Geolocation is not supported by this browser."); // set error node
        }
    }, [successHandler, errorHandler, enabled]);

    return { userLatLng, geoCoordsAreLoading: isLoading };
};
