import { NearestPartners } from "./useNearestPartners";
import { Partner } from "../usePartners/interfaces/Partner";
import { PartnerHashMap } from "../usePartners/usePartners";
import { getDistanceFromLatLonInKm } from "./getDistanceFromLatLonInKm";

interface coords {
  lat: number;
  lng: number;
}

interface DistancePartner {
  distance: number;
  partner: Partner;
}

/**
 * Function returns an array of _indexes_ sorted by the lat lng distance between each point and the location
 * @param  { Array }    markers     Array of points on a map with lat and lng props
 * @param  { Object }   location    A point consisting of lat and lng
 * @return { Array }
 */
export const findNearestPartners = (
  partnersMap: PartnerHashMap,
  location: coords
): NearestPartners => {
  if (!location.lat || !location.lng) {
    throw new Error("Missing Lat Lng Coords in location!");
  }

  const partnerArray: Partner[] = Object.values(partnersMap);

  const partnersWithDistance: DistancePartner[] = partnerArray.map(
    (partner) => {
      let badgesCount = 0;

      // Number of badges reduce the distance
      Object.values(partner.badges).forEach((badge) => {
        if (badge) {
          badgesCount += 1;
        }
      });

      let factor;
      switch (badgesCount) {
        case 5:
          factor = 2;
          break;
        case 4:
          factor = 1.75;
          break;
        case 3:
          factor = 1.5;
          break;
        default:
          factor = 1;
      }

      const distance =
        getDistanceFromLatLonInKm({
          partnerLat: partner.lat,
          partnerLng: partner.lng,
          currentLocationLat: location.lat,
          currentLocationLng: location.lng,
        }) / factor;

      return { distance, partner };
    }
  );

  // sort the array in ascending order of distance
  partnersWithDistance.sort((a, b) => {
    return a.distance - b.distance;
  });

  const nearestPartners: NearestPartners = {
    topPartner: partnersWithDistance
      .slice(0, 3)
      .map((partner) => partner.partner),
    otherPartner: partnersWithDistance
      .slice(3, 6)
      .map((partner) => partner.partner),
  };

  return nearestPartners;
};
