import { Grid, Box, Text, Button, Anchor } from "@mantine/core";
import styles from "./CookieBanner.module.css";
import { useState } from "react";
import { CookieModal } from "../CookieModal/CookieModal";
import { useCookies } from "react-cookie";

export const CookieBanner = () => {
    const [cookies, setCookie] = useCookies(["cookieShown"]);

    const handleCookiesSet = () => {
        setCookie("cookieShown", true, { maxAge: 30 * 24 * 60 * 60 });
    };
    const [opened, setOpened] = useState<boolean>(false);

    return !cookies.cookieShown ? (
        <Box className={styles.cookieBannerContainer}>
            <Grid align="center">
                <Grid.Col span={10} p="md">
                    <Text className={styles.cookieBannerText}>
                        Auch diese Seite nutzt Cookies. Technische und funktionale Cookies benötigen wir zwingend, damit
                        bei Ihrem Besuch unserer Website alles gelingt. Zudem können wir mit Webanalyse unsere Website
                        für Sie verbessern und eventuelle Fehler erkennen und erheben.
                    </Text>
                    <Anchor className={styles.cookieBannerLink} onClick={() => setOpened(true)}>
                        Detailinformationen zu den Cookies
                    </Anchor>
                </Grid.Col>
                <Grid.Col span={1}>
                    <Button h="100%" onClick={handleCookiesSet} variant="default" className={styles.cookieBannerButton}>
                        <Text fz={19.2}>Okay</Text>
                    </Button>
                </Grid.Col>
            </Grid>
            <CookieModal isOpen={opened} onClose={() => setOpened(false)} />
        </Box>
    ) : null;
};
