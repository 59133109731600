export const geoSettings = {
    enableHighAccuracy: true,
    maximumAge: 6000,
    timeout: 15000,
};

export const SPLASH_SCREEN_DURATION = 1000;

export const YELLOW_MAP_INITIAL_ZOOM = 15;
export const YELLOW_MAP_MIN_ZOOM = 1;
export const YELLOW_MAP_CLUSTERING_ZOOM = 20;
export const YELLOW_MAP_MAX_RESULTS = 999;
export const YELLOW_MAP_AUTOSUGGEST_MIN_CHARS = 3;
export const YELLOW_MAP_AUTOSUGGEST_MAX_RESULTS = 10;
