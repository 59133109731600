import "@mantine/core/styles.css";
import "./Fonts.css";

import { Home } from "./components/Home";
import { Suspense } from "react";
import { CookiesProvider } from "react-cookie";

function App() {
    return (
        <Suspense>
            <CookiesProvider>
                <Home />
            </CookiesProvider>
        </Suspense>
    );
}

export default App;
