import { atom, useAtom } from "jotai";

const showSelectedPartnerModalAtom = atom<boolean>(false);

export const useShowSelectedPartnerModal = () => {
  const [showSelectedPartnerModal, setShowSelectedPartnerModal] = useAtom(
    showSelectedPartnerModalAtom
  );
  return { showSelectedPartnerModal, setShowSelectedPartnerModal };
};
