import React from "react";
import { useLocation } from "../../../hooks/useLocation";
import { moveToLocation } from "./MapFunctions";
import { LatLng } from "../../../utils/models";
import { useHomeMarker } from "./useHomeMarker";

export function useHomeAddress(options: { ymReady: boolean; userLatLng?: LatLng }) {
    const { ymReady, userLatLng } = options;

    const [isUserLocationInitialized, setUserLocationInitialized] = React.useState(false);

    const { setUserLatLng } = useLocation();

    const setHomeMarker = useHomeMarker({ ymReady, userLatLng });

    React.useEffect(
        function setUserLoc() {
            if (!ymReady) {
                return;
            }

            if (isUserLocationInitialized) {
                return;
            }

            if (!userLatLng) {
                return;
            }

            window.ym.ready(async function () {
                setUserLatLng(userLatLng);
                moveToLocation(userLatLng.lat, userLatLng.lng);
                setUserLocationInitialized(true);
                setHomeMarker;
            });
        },
        [ymReady],
    );
}
