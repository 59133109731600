import { getUserLocationIcon } from "../../../utils/icon-utils";
import { Map, LatLngRect, MapMarker, MarkerClusterGroup } from "../../../utils/models";
import { MARKER_ZINDEX_DEFAULT, MARKER_ZINDEX_SELECTED } from "./MapVariables";
import { getGlobalMap } from "./mapInstance";
import { Partner } from "../../../hooks/usePartners/interfaces/Partner";

export const moveToLocation = (lat: number, long: number) => {
    const center = window.ym.latLng(lat, long);
    getGlobalMap()?.panTo(center);
};

export const animateToLocation = (lat: number, long: number, zoom = getGlobalMap()?.getZoom()) => {
    const center = window.ym.latLng(lat, long);
    getGlobalMap()?.setView(center, zoom, { animate: true });
};

export const renderMarker = (
    lat: number,
    long: number,
    target: Map | MarkerClusterGroup | null,
    //
    modules: any,
    icon: any,
    clickCallback?: any,
    options?: any,
) => {
    if (!target) {
        return null;
    }

    const pos = window.ym.latLng(lat, long);
    const m = new modules.provider.Marker(pos, options) as MapMarker;
    m.setIcon(icon);
    m.on("click", function (e: any) {
        if (clickCallback) clickCallback(e.target.options.partnerId);
    });
    m.addTo(target);

    return m;
};

// export const calculateZoomLevel = (atms: any[]) => {
//     if (atms && atms.length) {
//         const maxDistance = Number(atms[atms.length - 1].BasicData.Geo.Distance);
//         if (maxDistance === 0) return -1;
//         if (maxDistance <= 300) return 16;
//         if (maxDistance > 300 && maxDistance <= 600) return 15;
//         if (maxDistance > 600 && maxDistance <= 1200) return 14;
//     }
//     return 13;
// };

export const getBounds = (map = getGlobalMap()) => {
    if (!map) {
        return null;
    }
    const bounds = map.getBounds();
    const northWest = bounds.getNorthWest();
    const southEast = bounds.getSouthEast();
    return {
        northWest: {
            lat: northWest.lat as number,
            lng: northWest.lng as number,
        },
        southEast: {
            lat: southEast.lat as number,
            lng: southEast.lng as number,
        },
    } as LatLngRect;
};

export function createMarker(options: { partner: Partner; onClick: (partnerId: string) => void; target: any }) {
    const { partner, onClick } = options;

    const icon = getUserLocationIcon();
    const marker = renderMarker(
        parseFloat(partner.lat),
        parseFloat(partner.lng),
        options.target,
        window.ym.modules,
        icon,
        onClick,
        {
            partnerId: partner.id,
        },
    );

    marker?.setZIndexOffset(getMarkerZIndexOffset());

    return marker;
}

export function getMarkerZIndexOffset(selected = false) {
    return selected ? MARKER_ZINDEX_SELECTED : MARKER_ZINDEX_DEFAULT;
}

export function updateMarkerIcon({ marker, partner }: { marker?: MapMarker | null; partner?: Partner }) {
    if (!marker || !partner) {
        return;
    }

    marker.setZIndexOffset(getMarkerZIndexOffset());
    marker.setIcon(getUserLocationIcon());
}
