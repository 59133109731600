import { Anchor, Breadcrumbs, Text } from "@mantine/core";
import styles from "./PageEndBar.module.css";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE, PARTNER_PATH } from "../Home";
import { useSelectedPartner } from "../../hooks/useSelectPartner";

export const PageEndBar = () => {
    const { selectedPartner, setSelectedPartner } = useSelectedPartner();

    const navigate = useNavigate();

    const handleHomeLinkClick = () => {
        navigate(HOME_ROUTE);
        window.scrollTo({ top: 0 });
        setSelectedPartner(undefined);
    };

    const handlePartnerLinkClick = () => {
        if (!selectedPartner) return;
        navigate(PARTNER_PATH(selectedPartner.id));
    };

    return (
        <Breadcrumbs separator="›" mt="lg">
            <Anchor onClick={handleHomeLinkClick} className={styles.breadcrumbLink}>
                <Text>Startseite</Text>
            </Anchor>
            {selectedPartner ? (
                <Anchor onClick={handlePartnerLinkClick} className={styles.breadcrumbLink}>
                    <Text>Berater</Text>
                </Anchor>
            ) : undefined}
        </Breadcrumbs>
    );
};
