import React from "react";
import { moveToLocation } from "./MapFunctions";

/**
 * Scrolls the map to the given coordinates once, as soon as the map is ready.
 *
 * @param params
 */
export function useAutoMoveTo(params: { ymReady: boolean; lat?: number; lng?: number }) {
    const { ymReady, lat, lng } = params;
    React.useEffect(() => {
        if (ymReady && lat && lng) {
            moveToLocation(lat, lng);
        }
    }, [ymReady, lat, lng]);
}
