import { Space, Stack } from "@mantine/core";
import { TopPartner } from "./TopPartner";
import { MorePartners } from "./morePartners";
import { MainPage } from "./MainPage/MainPage";

export const IngContent = () => {
  return (
    <Stack>
      <TopPartner />
      {/** TODO: Nur bei Location oder suche soll der nächste Block sichtbar sein */}

      <MorePartners />
      <Space h="lg" />
      <MainPage />
    </Stack>
  );
};
