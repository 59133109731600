import { Anchor, Group, Image, Paper, Stack, Text } from "@mantine/core";

import styles from "./SmallPartnerTeaser.module.css";
import { Partner } from "../../hooks/usePartners/interfaces/Partner";
import { useNavigate } from "react-router-dom";
import { PARTNER_PATH } from "../Home";
import { moveToLocation } from "../YellowMaps/hooks/MapFunctions";

interface SmallPartnerTeaserProps {
    partner: Partner;
}

export const SmallPartnerTeaser: React.FC<SmallPartnerTeaserProps> = (props: SmallPartnerTeaserProps) => {
    const { partner } = props;

    const navigate = useNavigate();

    const handleLinkClick = () => {
        navigate(PARTNER_PATH(partner.id));
        moveToLocation(parseFloat(partner.lat), parseFloat(partner.lng));
        window.scrollTo({ top: 0 });
    };

    const imageUrl = partner?.portrait ? partner?.portrait?.thumb : "/site/assets/files/assets/profil-dummy.png";

    return (
        <Paper p="lg" radius="12px" className={styles.previewContainer}>
            <Group wrap="nowrap" dir="row">
                <Group className={styles.previewImage}>
                    <Image src={imageUrl} />
                </Group>
                <Stack>
                    <Stack gap={0}>
                        <Text className={styles.previewName}>
                            {`${partner.firstname} ${partner.lastname}`.replace("&amp;", "&")}
                        </Text>
                        <Text className={styles.previewCity}>{`${partner.zip} ${partner.city}`}</Text>
                    </Stack>

                    <Anchor underline="never" onClick={handleLinkClick} className={styles.partnerTeaserLink}>
                        <Text>Profil ansehen</Text>
                    </Anchor>
                </Stack>
            </Group>
        </Paper>
    );
};
