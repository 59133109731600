import { Anchor, Box, Button, Checkbox, Stack, Text, Title } from "@mantine/core";

import styles from "./AcceptTerms.module.css";

interface AcceptTermsProps {
    handleTermAcceptClick: () => void;
    handleCheckedBox: () => void;
}

const AcceptTerms: React.FC<AcceptTermsProps> = (props: AcceptTermsProps) => {
    const { handleTermAcceptClick, handleCheckedBox } = props;
    return (
        <Box className={styles.termsOverlay}>
            <Stack>
                <Title fw="400" className={styles.termsOverlayHeadline} order={2}>
                    Finden Sie Ihren Bau&shy;fi&shy;nan&shy;zie&shy;rungs&shy;be&shy;ra&shy;ter.
                </Title>
                <Title className={styles.termsOverlaySubheadline} order={3}>
                    Mit bester Empfehlung und kostenlos
                </Title>
                <Text className={styles.termsOverlayText} fw="500">
                    Datenschutz-Hinweis
                </Text>
                <Text className={styles.termsOverlayText}>
                    Für die Karten-Darstellung und die Ermittlung der nächstgelegenen Beraterinnen und Berater verwenden
                    wir den Karten- und Lokalisierungsdienst von YellowMap. Hierfür erhält YellowMap die IP-Adresse
                    Ihres Geräts. Weitere Informationen finden Sie{" "}
                    <Anchor
                        className={styles.termsOverlayAnchor}
                        href="https://www.yellowmap.com/datenschutzhinweise/"
                        target="_blank"
                        rel="noreferrer"
                        underline="always"
                    >
                        hier
                    </Anchor>
                    .
                </Text>
                <Button className={styles.termsOverlayButton} onClick={handleTermAcceptClick}>
                    Einwilligen und Suche starten
                </Button>
                <form>
                    <Checkbox
                        label="Hinweis nicht mehr anzeigen"
                        id="save-decision"
                        color="#FF6200"
                        onClick={handleCheckedBox}
                    />
                </form>
            </Stack>
        </Box>
    );
};

export { AcceptTerms };
