export const ING_FRANKFURT_LATITUDE = 50.114263;
export const ING_FRANKFURT_LONGITUDE = 8.648883;
export const SMARTPHONE_UPPER_BOUND = 767;
export const TABLET_S_LOWER_BOUND = 768;
export const TABLET_S_UPPER_BOUND = 959;
export const TABLET_L_LOWER_BOUND = 960;
export const TABLET_L_UPPER_BOUND = 1200;
export const DESKTOP_LOWER_BOUND = 1201;
export const DESKTOP_UPPER_BOUND = 1440;
export const DEFAULT_PLACEHOLDER_VALUE = "Postleitzahl / Ort";
