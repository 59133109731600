import {
    Box,
    Container,
    Group,
    Stack,
    Autocomplete,
    Button,
    Image,
    Text,
    Loader,
    Overlay,
    Center,
} from "@mantine/core";
import React from "react";
import "../../YellowMaps.css";
import { MarkerHashMap } from "../../utils/models";

import { usePartners } from "../../hooks/usePartners/usePartners";
import { useResultMarkers } from "./hooks/useResultMarker";
import { useYellowMap } from "./hooks/useYellowMap";
import { ProfilePreview } from "../ProfilePreview/ProfilePreview";
import { useSelectedPartner } from "../../hooks/useSelectPartner";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE, PARTNER_PATH } from "../Home";

import { fetchSuggestions } from "../../api/AutocompleteAPI";
import { YELLOW_MAP_AUTOSUGGEST_MIN_CHARS, YELLOW_MAP_INITIAL_ZOOM } from "../../constants/configs";
import { GeoEntity } from "../../api/interfaces/GeoEntity";
import { useSelectedCity } from "../../hooks/useSelectedCity";
import { AcceptTerms } from "./AcceptTerms/AcceptTerms";
import { useAutoMoveTo } from "./hooks/useAutoMove";

import { useHomeAddress } from "./hooks/useHomeAddress";
import { useHomeMarker } from "./hooks/useHomeMarker";
import { useGeoLocation } from "./hooks/useGeoLocation";

import { animateToLocation } from "./hooks/MapFunctions";
import { useSearchMarker } from "./hooks/useSearchMarker";
import { useLocalStorage } from "@mantine/hooks";
import { useShowSelectedPartnerModal } from "../../hooks/useShowSelectedPartnerModal";
import { useGeolocationStatus } from "../../hooks/useGeolocationStatus";
import styles from "./YellowMapsContainer.module.css";
export const YellowMapsContainer = () => {
    const [mapElement, setMapElement] = React.useState<HTMLDivElement | null>(null);
    const [termAccept, setTermAccept] = React.useState<boolean>(false);
    const [checkedBox, setCheckedBox] = React.useState<boolean>(false);
    const [isBoxChecked, setBoxChecked] = useLocalStorage({
        key: "useYellowMaps",
        defaultValue: false,
    });
    const [searchResults, setSearchResults] = React.useState<GeoEntity[]>([]);
    const { selectedCity, setSelectedCity } = useSelectedCity();
    const [isSearchDirty, setSearchDirty] = React.useState<boolean>(false);

    const handleMapElement = React.useCallback(
        (node: React.SetStateAction<HTMLDivElement | null>) => setMapElement(node),
        [],
    );
    const { ymReady } = useYellowMap(mapElement);

    const markersById = React.useRef<MarkerHashMap>({});

    const partners = usePartners();
    const { selectedPartner, setSelectedPartner } = useSelectedPartner();
    const { setShowSelectedPartnerModal, showSelectedPartnerModal } = useShowSelectedPartnerModal();
    const { geolocationStatus, setGeolocationStatus } = useGeolocationStatus();
    const navigate = useNavigate();

    const handleCloseProfilePreview = () => {
        setSelectedPartner(undefined);
        setShowSelectedPartnerModal(false);
    };

    const handleProfileClick = () => {
        if (selectedPartner) {
            navigate(PARTNER_PATH(selectedPartner.id));
            automove;
            setShowSelectedPartnerModal(false);
        }
    };

    const handleTermAcceptClick = () => {
        if (checkedBox) {
            setBoxChecked(true);
        }
        setTermAccept(true);
    };

    const handleCheckedBoxClick = () => {
        setCheckedBox((current) => (current === false ? true : false));
    };

    const handleSearch = async (queryInput: string) => {
        const inputValue = queryInput.trim().toLowerCase();
        if (inputValue.length < YELLOW_MAP_AUTOSUGGEST_MIN_CHARS) {
            setSearchResults([]);
            return;
        }
        const result = await fetchSuggestions(queryInput);
        setSearchResults(result);
    };

    const handleDropSearchSelect = async (selectedSearchInputQuery: string) => {
        if (selectedSearchInputQuery) {
            const selectedCityEntity: GeoEntity | undefined = searchResults.find(
                (item) => item.displayValue === selectedSearchInputQuery,
            );

            setSelectedCity(selectedCityEntity);
            setSearchDirty(true);
            navigate(HOME_ROUTE);
            if (!isSearchDirty) {
                searchResultIcon;
            }
        }
    };

    const handleAcceptNoGeoCoords = () => {
        if (geolocationStatus) {
            setGeolocationStatus({
                ...geolocationStatus,
                useApplicationWithGeolocation: true,
            });
        }
    };

    const handleClickLocationButton = () => {
        setSelectedCity(undefined);
        setSelectedPartner(undefined);
        if (userLatLng) animateToLocation(userLatLng?.lat, userLatLng?.lng, YELLOW_MAP_INITIAL_ZOOM);
        navigate(HOME_ROUTE);
    };

    useResultMarkers({
        ymReady,
        partners,
        markersById,
    });

    const showTermOverlay = !termAccept && !isBoxChecked;

    const { userLatLng, geoCoordsAreLoading } = useGeoLocation({ ymReady, enabled: !showTermOverlay });

    useHomeMarker({
        ymReady,
        userLatLng: userLatLng,
    });

    useHomeAddress({
        ymReady,
        userLatLng: userLatLng,
    });

    const searchResultIcon = useSearchMarker({
        ymReady,
        selectedCity,
        isDirty: isSearchDirty,
    });

    const automove = useAutoMoveTo({
        ymReady,
        lng: selectedCity?.geometry.coordinates[0],
        lat: selectedCity?.geometry.coordinates[1],
    });

    const geoLocationIsDisabled = !geolocationStatus?.isEnabled;

    return (
        <Container
            size="xl"
            w={"100%"}
            style={{
                position: "relative",
            }}
            mt="-30"
        >
            {!showTermOverlay &&
                geoLocationIsDisabled &&
                geolocationStatus?.useApplicationWithGeolocation === false && (
                    <Overlay style={{ zIndex: 1001 }}>
                        <Center h={"100%"}>
                            <Stack>
                                <Text style={{ color: "#FFFFFF" }} fz={"lg"}>
                                    Ihre aktuelle Position konnte nicht ermittelt werden. Bitte aktivieren Sie ihre
                                    Ortungsdienste.
                                </Text>
                                <Group justify="center" wrap="nowrap">
                                    <Button
                                        className={styles.geoLocationDisabledOverlayButton}
                                        onClick={handleAcceptNoGeoCoords}
                                    >
                                        <Text className={styles.geoLocationDisabledOverlayButtonText}>Okay</Text>
                                    </Button>
                                </Group>
                            </Stack>
                        </Center>
                    </Overlay>
                )}
            {!showTermOverlay && geoCoordsAreLoading && (
                <Overlay style={{ zIndex: 1001 }}>
                    <Center h={"100%"}>
                        <Stack align="center">
                            <Loader color="#FF6200" type="bars" />
                            <Text style={{ color: "#FFFFFF" }} fz={"lg"}>
                                Ihre aktuelle Position wird ermittelt, bitte haben Sie einen Moment lang Geduld.
                            </Text>
                        </Stack>
                    </Center>
                </Overlay>
            )}
            {!showTermOverlay ? (
                <>
                    <Box
                        w={380}
                        style={{
                            position: "absolute",
                            zIndex: 500,
                        }}
                    >
                        <Stack>
                            <Group mt="md" ml="md" wrap="nowrap">
                                <Autocomplete
                                    placeholder="Geben Sie einen Standort ein."
                                    onChange={handleSearch}
                                    data={searchResults.map((item) => item.displayValue)}
                                    styles={{ dropdown: { zIndex: 500 }, root: { zIndex: 500 } }}
                                    onOptionSubmit={handleDropSearchSelect}
                                    w={300}
                                />

                                <Button
                                    pl="xs"
                                    pr="xs"
                                    bg={geoLocationIsDisabled ? "gray" : "#FF6200"}
                                    onClick={handleClickLocationButton}
                                    disabled={geoLocationIsDisabled}
                                >
                                    <Image  style={{ flex: "auto" }} src="/site/assets/files/assets/location-icon.png" />
                                </Button>
                            </Group>
                            {showSelectedPartnerModal && selectedPartner ? (
                                <ProfilePreview
                                    selectedPartner={selectedPartner}
                                    handleClose={handleCloseProfilePreview}
                                    onProfileClick={() => handleProfileClick()}
                                />
                            ) : undefined}
                        </Stack>
                    </Box>
                    <Box
                        ref={handleMapElement}
                        id="map"
                        style={{
                            height: "350px",
                            position: "relative",
                        }}
                    ></Box>
                </>
            ) : (
                <AcceptTerms handleTermAcceptClick={handleTermAcceptClick} handleCheckedBox={handleCheckedBoxClick} />
            )}
        </Container>
    );
};
