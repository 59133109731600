import { ResponsiveType, getResponsiveType } from "./responsive-utils";

const MarkerIconSizes = {
    [ResponsiveType.PHONE]: [34, 40],
    [ResponsiveType.TABLET_SMALL]: [34, 40],
    //
    [ResponsiveType.TABLET_LARGE]: [38, 45],
    //
    [ResponsiveType.DESKTOP_SMALL]: [46, 55],
    [ResponsiveType.DESKTOP_LARGE]: [46, 55],
};

interface IconUrlOptions {
    retina?: boolean;
    responsive?: boolean;
}

function applyRetina(iconUrl: string) {
    if (window.devicePixelRatio >= 3) {
        return iconUrl.replace("/sm/", "/sm-3x/").replace("/md/", "/md-3x/").replace("/lg/", "/lg-3x/");
    }
    if (window.devicePixelRatio >= 2) {
        return iconUrl.replace("/sm/", "/sm-2x/").replace("/md/", "/md-2x/").replace("/lg/", "/lg-2x/");
    }
    return iconUrl;
}

function applyResponsive(iconUrl: string) {
    switch (getResponsiveType()) {
        case ResponsiveType.PHONE:
        case ResponsiveType.TABLET_SMALL:
            return iconUrl.replace("/lg/", "/sm/");

        case ResponsiveType.TABLET_LARGE:
            return iconUrl.replace("/lg/", "/md/");

        case ResponsiveType.DESKTOP_SMALL:
        case ResponsiveType.DESKTOP_LARGE:
        default:
            return iconUrl;
    }
}

function applyOptions(url: string, { retina = true, responsive = true }: IconUrlOptions = {}) {
    if (responsive) {
        url = applyResponsive(url);
    }

    if (retina) {
        url = applyRetina(url);
    }

    return url;
}

export function getIconUrlFromUrl(url: string, options?: IconUrlOptions) {
    const finalUrl = applyOptions(url, options);
    return finalUrl;
}

export function getUserLocationIcon() {
    const url = "/site/assets/files/assets/Icon-Partner.svg";
    const iconUrl = getIconUrlFromUrl(url);
    const iconRetinaUrl = getIconUrlFromUrl(url, { retina: true });
    const iconSize = MarkerIconSizes[getResponsiveType()];

    const icon = new window.ym.modules.provider.Icon({
        iconUrl,
        iconRetinaUrl,
        iconSize,
    });

    return icon;
}
