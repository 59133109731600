import { Anchor, Group, Image, List, Paper, Stack, Text, Tooltip } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import styles from "./PartnerTeaser.module.css";

import { Partner } from "../../hooks/usePartners/interfaces/Partner";
import { PARTNER_PATH } from "../Home";
import { moveToLocation } from "../YellowMaps/hooks/MapFunctions";
import { useSelectedPartner } from "../../hooks/useSelectPartner";

interface PartnerTeaserProps {
    partner: Partner;
}

const PartnerTeaser: React.FC<PartnerTeaserProps> = (props: PartnerTeaserProps) => {
    const { partner } = props;
    const { setSelectedPartner } = useSelectedPartner();

    const navigate = useNavigate();

    const handleLinkClick = () => {
        navigate(PARTNER_PATH(partner.id));
        setSelectedPartner(partner);
        moveToLocation(parseFloat(partner.lat), parseFloat(partner.lng));
        window.scrollTo({ top: 0 });
    };

    const imageUrl = partner?.portrait ? partner?.portrait?.thumb : "/site/assets/files/assets/profil-dummy.png";

    return (
        <Paper className={styles.partnerTeaserContainer}>
            <Stack justify="space-between" h={"100%"}>
                <Stack>
                    <Stack gap="2px">
                        <Text className={styles.partnerTeaserName}>
                            {`${partner.firstname} ${partner.lastname}`.replace("&amp;", "&")}
                        </Text>
                        <Text className={styles.partnerTeaserCity}>
                            {partner.zip} {partner.city}
                        </Text>
                    </Stack>
                    <Group align="center" dir="row" wrap="nowrap">
                        <Group className={styles.partnerImage}>{partner.portrait && <Image src={imageUrl} />}</Group>
                        <Group h="70px" flex-dir="row" gap="5" align="flex-start">
                            {partner.badges.starke_leistung && (
                                <Tooltip label="Starke Leistung" withArrow>
                                    <Image
                                        src="/site/assets/files/assets/badge-leistung.svg"
                                        className={styles.partnerTeaserIcon}
                                    />
                                </Tooltip>
                            )}
                            {partner.badges.top_qualitaet && (
                                <Tooltip label="Top Qualität" withArrow>
                                    <Image
                                        src="/site/assets/files/assets/badge-quality.svg"
                                        className={styles.partnerTeaserIcon}
                                    />
                                </Tooltip>
                            )}
                            {partner.badges.hohe_kundenorientierung && (
                                <Tooltip label="Hohe Kundenorientierung" withArrow>
                                    <Image
                                        src="/site/assets/files/assets/badge-kundenorientierung.svg"
                                        className={styles.partnerTeaserIcon}
                                    />
                                </Tooltip>
                            )}
                            {partner.badges.breites_ing_portfolio && (
                                <Tooltip label="Breites ING-Portfolio" withArrow>
                                    <Image
                                        src="/site/assets/files/assets/badge-portfolio.svg"
                                        className={styles.partnerTeaserIcon}
                                    />
                                </Tooltip>
                            )}
                            {partner.badges.ausgezeichnete_kompetenz && (
                                <Tooltip label="Ausgezeichnete Kompetenz" withArrow>
                                    <Image
                                        src="/site/assets/files/assets/badge-special-know-how.svg"
                                        className={styles.partnerTeaserIcon}
                                    />
                                </Tooltip>
                            )}
                            {partner.badges.spezial_wissen_modernisierung && (
                                <Tooltip label="Spezial-Wissen Modernisierung" withArrow>
                                    <Image
                                        src="/site/assets/files/assets/badge-green-know-how.svg"
                                        className={styles.partnerTeaserIcon}
                                    />
                                </Tooltip>
                            )}
                        </Group>
                    </Group>
                    <List ta="left">
                        {partner.facts.map((item, index) => {
                            return (
                                <List.Item key={index} className={styles.partnerTeaserList}>
                                    {item}
                                </List.Item>
                            );
                        })}
                    </List>
                </Stack>
                <Group>
                    <Anchor underline="never" onClick={handleLinkClick} className={styles.partnerTeaserLink}>
                        <Text>Profil ansehen</Text>
                    </Anchor>
                </Group>
            </Stack>
        </Paper>
    );
};

export { PartnerTeaser };
