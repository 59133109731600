import React from "react";

import { moveToLocation } from "./MapFunctions";
import { Partner } from "../../../hooks/usePartners/interfaces/Partner";
import { PartnerHashMap } from "../../../hooks/usePartners/usePartners";
import { useSelectedPartner } from "../../../hooks/useSelectPartner";
import { useShowSelectedPartnerModal } from "../../../hooks/useShowSelectedPartnerModal";

/**
 * Provides a handler function for marker clicks.
 * The function will handle the click at certain coordinates,
 * and dispatch a bunch of redux actions to apply our display and business logic.
 *
 * @param param0
 * @returns
 */
export function useMarkerClick({ partners }: { partners?: PartnerHashMap }) {
  const { setSelectedPartner } = useSelectedPartner();
  const { setShowSelectedPartnerModal } = useShowSelectedPartnerModal();
  const onMarkerClick = React.useCallback(
    function handleMarkerClick(addressItemId: string) {
      const partnersArray = Object.values(partners || {});
      if (!partnersArray.length) {
        return;
      }
      const partner = partnersArray.find((partner: Partner) => {
        return partner.id === addressItemId;
      });
      if (partner) {
        moveToLocation(parseFloat(partner.lat), parseFloat(partner.lng));
        setSelectedPartner(partner);
        setShowSelectedPartnerModal(true);
      }
    },
    [partners]
  );

  return { onMarkerClick };
}
