import { Box, Button, Group, Text, TextInput, Textarea } from "@mantine/core";
import styles from "./ContactForm.module.css";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { useSendForm } from "./useSendForm";

export interface ContactFormData {
    fullname: string;
    email: string;
    landline: string;
    message: string;
    action: string;
    id: string;
}

interface ContactFormProps {
    partnerId: string;
}

const ContactForm: React.FC<ContactFormProps> = (props: ContactFormProps) => {
    const { partnerId } = props;

    const form = useForm<ContactFormData>({
        initialValues: {
            fullname: "",
            email: "",
            landline: "",
            message: "",
            action: "contact",
            id: partnerId,
        },

        validate: {
            fullname: (value) => (value.length < 2 ? "Ihr Name muss mindestens 2 Zeichen lang sein." : null),
            email: isEmail("Bitte geben Sie eine gültige E-Mail Adresse an"),
            landline: (value) => (/^[0-9]*$/.test(value) ? null : "Bitte geben Sie eine gültige Telefonnummer an"),
            message: isNotEmpty("Bitte ergänzen Sie Ihren Kommentar"),
        },
        validateInputOnChange: false,
    });
    const { sendForm, formSend, hasFormError } = useSendForm(form.values);
    const handleFormSubmit = () => {
        if (form.isValid()) {
            sendForm();
            form.reset();
        }
    };

    return (
        <Box ml={"0"} mx="auto">
            {hasFormError && (
                <Box bg={"red"} mb={"lg"} style={{ borderRadius: "6px" }}>
                    <Text className={styles.formSendSuccess}>Fehler: Ihre Anfrage konnte nicht versendet werden.</Text>
                </Box>
            )}
            {formSend && !hasFormError && (
                <Box bg={"#00aa00"} mb={"lg"} style={{ borderRadius: "6px" }}>
                    <Text className={styles.formSendSuccess}>Ihre Anfrage wurde versendet.</Text>
                </Box>
            )}

            <form onSubmit={form.onSubmit(handleFormSubmit)}>
                <TextInput
                    withAsterisk
                    label="Name"
                    {...form.getInputProps("fullname")}
                    classNames={{ label: styles.label }}
                />

                <TextInput
                    mt="sm"
                    withAsterisk
                    label="E-Mail"
                    {...form.getInputProps("email")}
                    classNames={{ label: styles.label }}
                />

                <TextInput
                    mt="sm"
                    label="Telefon (optional)"
                    {...form.getInputProps("landline")}
                    classNames={{ label: styles.label }}
                />

                <Textarea
                    mt="sm"
                    withAsterisk
                    label="Nachricht"
                    autosize
                    minRows={4}
                    {...form.getInputProps("message")}
                    classNames={{ label: styles.label }}
                />

                <Text mt="sm" className={styles.partnerCategorieHeadline}>
                    Einwilligung
                </Text>
                <Group wrap="nowrap" mt="sm" align="flex-start">
                    <Text className={styles.partnerParagraph}>
                        Ich willige ein, dass die ING-DiBa AG meine oben genannten Daten an die von mir ausgesuchte
                        Person (Baufinanzierungsberater*in) weiterleiten kann, damit sie mich kontaktieren kann. Die
                        Einwilligung kann ich mit Wirkung für die Zukunft jederzeit ohne Angabe von Gründen per E-Mail
                        an die ausgesuchte Person widerrufen.
                    </Text>
                </Group>
                <Group justify="flex-start" mt="xl">
                    <Button className={styles.partnerFormButton} type="submit">
                        Einwilligen und E-Mail abschicken
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

export { ContactForm };
