// TODO: possibly we need token invalidation - we will receive info from YM about token lifespan

import { CONFIG } from "../../config/viteConfig";
import { YELLOW_MAP_AUTOSUGGEST_MAX_RESULTS } from "../constants/configs";

import { GeoEntity } from "./interfaces/GeoEntity";

// see https://jira.etecture.de/browse/INGGA-191
let AuthToken = "";

let abortController: AbortController;
let abortSignal: AbortSignal;

const fetchToken = async () => {
    const SecurityID = CONFIG.securityId;
    const SystemPartner = CONFIG.partnerId;
    const baseUrl = CONFIG.yellowMapApiRoot;

    const url = `${baseUrl}/v2/yellowmapsapi/authtoken/autocomplete?SystemPartner=${SystemPartner}&SecurityID=${SecurityID}&Channel=`;
    const response = await fetch(url, { signal: abortSignal });
    const token = await response.json();
    return token;
};
export const fetchSuggestions = async (
    query: string,
    maxCount = YELLOW_MAP_AUTOSUGGEST_MAX_RESULTS,
): Promise<GeoEntity[]> => {
    // abort pending requests
    abortController?.abort();

    // always prepare new abort controller+signal
    abortController = new AbortController();
    abortSignal = abortController.signal;

    // ensure we do have a token
    AuthToken = AuthToken || (await fetchToken());

    if (!AuthToken || !query) {
        return [];
    }

    //TODO filteroptions ausprobieren
    try {
        const url = CONFIG.yellowMapsAutocompleteUrl;
        const reqBody = {
            query: query,
            isoCountries: ["DE"],
            filterOptions: {
                plzSearch: false,
                includedGeoEntities: ["CITY_WITH_ZIP", "CITYPART_WITH_ZIP", "CITY", "CITYPART", "STREET"],
            },
            top: maxCount,
        };

        const result = await fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${AuthToken}`,
            },
            body: JSON.stringify(reqBody),
        });

        const { geoEntities } = await result.json();
        return geoEntities;
    } catch (error) {
        if ((error as Error).name !== "AbortError") {
            console.warn("[fetchSuggestions] failed", { error });
        }
    }

    return [];
};
