export const CONFIG = {
    // BASE URLS
    baseUrl: import.meta.env.VITE_BACKEND_BASE_URL ?? "https://www.mein-baufinanzierungsberater.de",
    apiKey: import.meta.env.VITE_API_KEY ?? "",
    securityId: import.meta.env.VITE_SECURITY_ID ?? "",
    partnerId: import.meta.env.VITE_PARTNER ?? "",
    newApiKey: import.meta.env.VITE_NEW_API_KEY ?? "",
    inlineRuntimeChunk: import.meta.env.INLINE_RUNTIME_CHUNK ?? false,
    version: import.meta.env.VITE_VERSION ?? "",
    yellowMapApiRoot: import.meta.env.VITE_YELLOW_MAP_API_ROOT ?? "",
    yellowMapsAutocompleteUrl: import.meta.env.VITE_YELLOW_MAP_AUTOCOMPLETE_URL ?? "",
};
